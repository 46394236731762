import { filename } from 'pathe/utils'
// https://github.com/nuxt/nuxt/issues/14766
// https://www.lichter.io/articles/nuxt3-vue3-dynamic-images/
export const useImages = () => {
  const glob = import.meta.glob(`~/assets/images/**/*.(png|svg)`, {
    eager: true
  })

  const images = Object.fromEntries(
    Object.entries(glob).map(([key, value]: [string, any]) => [
      filename(key),
      value.default
    ])
  )

  const getImageSrc = (fileName: string): string | undefined => {
    return images[fileName]
  }
  return { getImageSrc }
}
