<template>
  <div v-if="isShow" class="container_intrs">
    <div class="wrap_intrs" @click="onClickHide">
      <div class="inner_intrs">
        <section class="section_action">
          <atoms-default-button
            class="btn_none"
            text="오늘 하루 보지 않기"
            @click.stop="doNotShowToday"
          >
            <atoms-c-icon icon-name="check_circle" />
          </atoms-default-button>
          <atoms-default-button
            class="btn_close"
            text="닫기"
            @click="a2sSendLog('_close')"
          >
            <atoms-c-icon icon-name="x_circle" />
          </atoms-default-button>
        </section>
        <ul class="list_intrs">
          <li
            v-for="(el, idx) in intrs"
            :key="idx"
            class="item_intrs"
            :class="{ active: current === idx }"
          >
            <div
              v-if="el.mediaType === 'VIDEO'"
              :id="`vidIntrs${el.id}`"
              class="wrap_video"
            ></div>
            <div v-else class="wrap_img">
              <img
                alt="image"
                :class="{ linkable: el?.url }"
                :src="el.imageUrl"
                @click.stop="onClickDetail"
              />
            </div>
          </li>
        </ul>
        <section class="section_option" @click.stop>
          <div v-if="isShowControl" class="control_intrs">
            <atoms-c-icon
              icon-name="arrow_right_circle"
              @click.stop="onClickButton('left')"
            />
            <p>
              <em>{{ current + 1 }}</em>
              /
              <span>{{ intrs.length }}</span>
            </p>
            <atoms-c-icon
              icon-name="arrow_right_circle"
              @click.stop="onClickButton('right')"
            />
          </div>
          <atoms-default-button
            v-if="currentItem?.mediaType === 'VIDEO' && currentItem?.url"
            class="btn_detail"
            text="상세 내용 보러가기"
            @click.stop="onClickDetail"
          >
            <atoms-c-icon icon-name="arrow_right" />
          </atoms-default-button>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash-es'
import { checkDiffTime } from '@/assets/js/utils'
import {
  VIDEO_URL_PARSER,
  CREATE_YOUTUBE_ELEMENT
} from '@/assets/js/videoUtils'

const config = useRuntimeConfig()
const router = useRouter()
const route = useRoute()
const nuxtApp = useNuxtApp()
const { $api, $sendLog } = nuxtApp

const COOKIE_NAME = '_diabled_ad_hello'
const isNotShowDate = useCookie<number>(COOKIE_NAME, {
  path: '/',
  maxAge: 60 * 60 * 24 // 1일
})
isNotShowDate.value = isNotShowDate.value ?? 0
const isShow = ref<boolean>(false)
const intrs = ref<any[]>([])
const timerId = ref<ReturnType<typeof setInterval>>()
const current = ref<number>(0)
const players = ref<{ [key: string]: any }>({})

const isShowControl = computed(() => intrs.value.length > 1)
const currentItem = computed(() => intrs.value[current.value])

watch(isShow, (n) => {
  if (n) {
    a2sSendLog('_view', 'contents')
  } else {
    stopInterval()
  }
})

watch(currentItem, (n) => {
  const { id } = n

  if (!isEmpty(players.value)) {
    for (const key in players.value) {
      const { player, alreadyPlayed } = players.value[key]
      if (player) {
        if (!alreadyPlayed && id === (key as any) * 1) {
          player.playVideo()
        } else {
          player.pauseVideo()
        }
      }
    }
  }
})

onMounted(() => {
  const name: string = (route.name ?? '') as string
  if (/^notice/.test(name)) {
    return
  }
  // 웹뷰엔선 노출 금지
  const needShow = checkDiffTime({
    another: Number.isInteger(isNotShowDate.value * 1)
      ? isNotShowDate.value * 1
      : 0
  })
  if (isNotShowDate.value === 0 || needShow) {
    getIntersitialAds()
  }
})

const getIntersitialAds = async () => {
  try {
    const resp = await $api.banner.getInterstitialBanners()
    const { data } = resp as any
    if (Array.isArray(data) && data.length) {
      intrs.value = data
      intrs.value.forEach((banner) => {
        const { mediaType, videoUrl, id } = banner
        if (mediaType === 'VIDEO') {
          createYoutubeElement(videoUrl, id)
        }
      })
      isShow.value = true
      startInterval()
    }
  } catch (e) {
    console.log(e)
  }
}

const doNotShowToday = () => {
  a2sSendLog('_nomore')
  isNotShowDate.value = new Date().getTime()
  console.log(isNotShowDate.value)
  onClickHide()
}
const startInterval = () => {
  stopInterval()
  timerId.value = setInterval(() => {
    onClickButton('right')
  }, 5000)
}
const stopInterval = () => {
  clearInterval(timerId.value)
}
const createYoutubeElement = (videoUrl: string, bannerId: string) => {
  const { id, provider } = VIDEO_URL_PARSER(videoUrl)
  if (provider === 'youtube') {
    nextTick(() => {
      const $target = document.getElementById(`vidIntrs${bannerId}`)
      if ($target) {
        // 썸네일 이미지 삽입
        $target.style.backgroundImage = `url(
          "https://img.youtube.com/vi/${id}/hqdefault.jpg"
        )`
        CREATE_YOUTUBE_ELEMENT(
          id,
          $target,
          currentItem.value.id === bannerId, // 현재 아이템이 비디오 일 경우 자동 재생
          (player: any) => {
            player.addEventListener(
              'onStateChange',
              ({ data }: { data: any }) => {
                const YT = (window as any).YT
                switch (data) {
                  case YT.PlayerState.BUFFERING: // 3
                  case YT.PlayerState.PLAYING: // 1
                    stopInterval()
                    players.value[bannerId].alreadyPlayed = true
                    break
                  case YT.PlayerState.ENDED: // 0
                    onClickButton('right')
                    break
                }
              }
            )
            players.value[bannerId] = {
              player,
              alreadyPlayed: false
            }
          },
          true
        )
      }
    })
  }
}
const onClickDetail = () => {
  const { url, linkTargetType } = currentItem.value
  if (url) {
    const _linkTargetType = linkTargetType?.toLowerCase() ?? '_blank'
    a2sSendLog('_detail')
    if (_linkTargetType === '_self' && url.startsWith(config.public.baseURL)) {
      router.push(url.replace(config.public.baseURL, ''))
      onClickHide()
    } else {
      window.open(url, _linkTargetType)
    }
  }
}
const onClickButton = (type: string) => {
  if (type === 'left') {
    if (current.value - 1 < 0) {
      current.value = intrs.value.length - 1
    } else {
      current.value--
    }
  } else if (type === 'right') {
    if (current.value + 1 >= intrs.value.length) {
      current.value = 0
    } else {
      current.value++
    }
  }

  startInterval()
}
const onClickHide = () => {
  isShow.value = false
}

const a2sSendLog = (
  suffix: string = '',
  type: 'click' | 'contents' = 'click'
) => {
  $sendLog(`MainBanner${suffix}`, { BannerNo: currentItem.value.id }, type)
}
</script>

<style lang="scss">
@mixin w0-639 {
  @media (max-width: 639px) {
    @content;
  }
}
.container_intrs {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 13;
  .wrap_intrs {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .inner_intrs {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: content-box;
    display: inline-block;
    left: 0;
    right: 0;
    padding: 0 20px;
    width: calc(100% - 40px);
    max-width: 990px;
    .btn {
      display: flex;
      padding: 0;
      gap: 8px;
      align-items: center;
      > .txt_btn {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: -0.3px;
      }
    }
    .section_action {
      float: right;
      display: grid;
      grid-auto-flow: column;
      gap: 24px;
      margin-bottom: 16px;
      color: $white;
      > .btn {
        flex-direction: row-reverse;
        background-color: transparent;
      }
    }
    .list_intrs {
      clear: both;

      .item_intrs {
        position: relative;
        display: none;
        animation: fadeIn 0.2s ease-in-out;

        &.active {
          display: block;
        }
        .wrap_video {
          position: relative;
          padding-top: 56.25%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          > iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
      .wrap_img {
        display: flex;
        position: relative;
        padding-top: 56.25%;
        justify-content: center;
        overflow: hidden;
      }
      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100%;
        &.linkable {
          cursor: pointer;
        }
      }
    }
    .section_option {
      position: relative;
      margin-top: 16px;
      @include w0-639 {
        text-align: left;
      }
      .control_intrs {
        display: inline-flex;
        padding: 7px;
        margin: 0 auto;
        border-radius: 100px;
        background: rgba(0, 0, 0, 0.5);
        color: rgba(255, 255, 255, 0.8);
        gap: 8px;
        align-items: center;
        box-sizing: border-box;
        > p {
          display: flex;
          gap: 4px;
          font-size: 14px;
          > em {
            font-weight: 500;
            color: $white;
          }
        }
        .icon_arrow_right_circle {
          cursor: pointer;
          &:first-child {
            rotate: 180deg;
          }
        }
      }
      .btn_detail {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px 10px 4px 12px;
        height: 32px;
        border-radius: 32px;
        font-size: 15px;
        background-color: #f78029;

        &:not(:hover) > .icon {
          opacity: 0.6;
        }
        &:hover {
          background-color: #f06c27;
        }
      }
      @include w0-639 {
        .control_intrs,
        .btn_detail {
          border-radius: 30px;
          height: 30px;
        }
      }
    }
  }
}
</style>
