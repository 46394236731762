<template>
  <section class="section_more">
    <div class="bg_top"></div>
    <div class="content_main">
      <div class="wrap_bg">
        <div class="bg_block left" />
        <atoms-c-image
          class="bg_block right"
          file-name="main-more-cloud-2"
          :density="3"
        />
        <div class="area_btn">
          <div class="txt_more font_maple_story">
            <!-- eslint-disable-next-line -->
            <em>헬로메이플</em>
            을
            <br />
            더 자세히 알고 싶다면?
          </div>
          <atoms-border-button
            v-log-a2s-click
            class="btn_more"
            append-icon="chevron_right_rouned"
            color-pack="yellow"
            data-a2s-obj-name="Main_Seemore"
            @click="onClickMore"
          >
            헬로메이플 더 알아보기
          </atoms-border-button>
          <atoms-border-button
            v-log-a2s-click
            class="btn_youtube"
            append-icon="chevron_black"
            color-pack="gray"
            data-a2s-obj-name="Main_Youtube"
            @click="onClickYoutube"
          >
            <atoms-c-icon icon-name="youtube" />
            <span class="mo_only">공식 유튜브</span>
            <span class="pc_only">공식 유튜브 바로가기</span>
          </atoms-border-button>
        </div>
        <atoms-c-image
          class="bg_star_group"
          file-name="main-more-star-group"
          :density="3"
        />
        <atoms-c-image
          class="bg_friends"
          file-name="main-friends"
          :density="2"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
function onClickMore() {
  useRouter().push('introduce')
}
function onClickYoutube() {
  window.open(
    'https://www.youtube.com/channel/UChFCQ6ULqJ8ZWx9S1R5iz0Q',
    '_blank'
  )
}
</script>
<style lang="scss" scoped>
.section_more {
  overflow-x: hidden;
  background-color: #441a9d;
  @include imageSet('@/assets/images/bg-main-more');
  background-position: calc(50% - 314px) bottom;
  .bg_top {
    @include imageSet('@/assets/images/bg-main-more-top');
    background-position: center top -11px;
    padding-top: 44px;
  }
  .content_main {
    padding-top: 61px;
    .wrap_bg {
      width: 936px;
      display: flex;
      position: relative;
      padding-bottom: 73px;
      .bg_block {
        position: absolute;
        &.left {
          @include imageSet('@/assets/images/main-more-cloud');
          top: 25px;
          width: 176px;
          height: 88px;
          left: calc(-65px - 176px);
        }
        &.right {
          top: 154px;
          right: calc(-79px - 132px);
        }
      }
      .bg_star_group {
        position: absolute;
        top: 50px;
        left: 251px;
        pointer-events: none;
      }
      .bg_star {
        position: absolute;
        top: 58px;
        left: 313px;
        animation: rotate-animation 3s infinite ease-in-out;
      }
      @keyframes rotate-animation {
        0%,
        10% {
          transform: rotate(0deg) scale(1);
        }
        90%,
        100% {
          transform: rotate(71deg) scale(1.1);
        }
      }
      .bg_friends {
        position: absolute;
        left: -66px;
        top: 63px;
      }
      .area_btn {
        margin-left: auto;
        text-align: initial;
        .txt_more {
          text-align: left;
          font-size: 48px;
          line-height: 1.4;
          letter-spacing: -1.92px;
          margin-bottom: 24px;
          width: 420px;
          height: 134px;
          color: #ffffff;
          em {
            color: #ffe130;
          }
        }
        .btn_youtube {
          font-size: 16px;
          width: 266px;
          margin-top: 24px;
          padding: 12px 24px;
          .icon_youtube {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
@media (max-width: 999px) {
  .section_more {
    @include imageSet('@/assets/images/bg-main-more-mo');
    background-position: calc(50% - 126px) bottom;
    .bg_top {
      @include imageSet('@/assets/images/bg-main-more-top-mo');
      background-position: center top;
      padding-top: 35px;
    }
    .content_main {
      padding-top: 0;
      .wrap_bg {
        max-width: 600px;
        padding-bottom: 190px;
        .bg_block {
          &.left {
            @include imageSet('@/assets/images/main-more-cloud-mo');
            width: 75px;
            height: 75px;
            top: 5px;
            left: calc(50% - 179px);
          }
          &.right {
            width: 75px;
            top: 233px;
            right: calc(50% - 145px);
          }
        }
        .bg_star_group {
          position: absolute;
          top: 197px;
          left: calc(50% - 97px);
          width: 93.19px;
          height: 64.15px;
          z-index: 1;
        }
        .bg_friends {
          width: 236px;
          left: calc(50% - 250px);
          bottom: 42px;
          top: unset;
        }
        .area_btn {
          z-index: 1;
          margin-right: auto;
          .txt_more {
            width: auto;
            height: auto;
            text-align: center;
            margin-bottom: 16px;
            font-size: 28px;
            line-height: 1.4;
            letter-spacing: -1.12px;
          }
          .btn_more {
            width: 220px;
          }
          .btn_youtube {
            margin-top: 16px;
            width: 220px;
          }
        }
      }
    }
  }
}
@media (max-width: 599px) {
  .section_more {
    width: 100%;
    max-width: unset;
    background-position: left -50px bottom;
    .content_main {
      .wrap_bg {
        max-width: unset;
        width: 100%;
        .bg_star_group {
          left: -62px;
        }
        .bg_friends {
          left: 0;
        }
        .bg_star_group {
          left: 140px;
        }
      }
    }
  }
}
</style>
